export const routes = {
  home: "/",
  contact: "/contact",
  login: "/login",
  signup: "/signup",
  dashboard: "/dashboard",
  profile: "/profile",
  settings: "/settings",
  applications: "/applications",
  jobs: "/jobs",
  savedJobs: "/jobs/saved",
  emails: "/dashboard/emails",
  feedback: "/feedback",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  privacyPolicy: "/privacy"
}
